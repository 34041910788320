<template>
  <div class="page-index">
    <!--<div class="banner"></div>-->
    <router-link tag="div" class="banner" to="/onlineMeasure">
      <img src="../assets/images/banner-2.jpg" alt="" width="100%">
    </router-link>
    <!--  行业动态  -->
    <div class="section section-1">
      <div class="title">行业动态</div>
      <div class="content">
        <div class="left">
          <el-carousel trigger="click" height="480px" >
            <!--
            <el-carousel-item>
              <img src="../assets/images/swiper-img1.jpg" alt="" width="100%">
            </el-carousel-item>
            -->
            <el-carousel-item v-for="(item, index) in swiperData" :key="index" @click.native="toDetail(item)">
              <img :src="item.cover" alt="" width="100%">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="right">
      <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="财税政策" name="first">
        <div class="list" v-loading="loading">
          <div class="row-item" v-for="(item, n) in data1" :key="n" @click="toDetail(item)">
            <div class="desc">{{item.title}}</div><div class="time">{{moment(item.createTime).format("YYYY-MM-DD")}}</div>
          </div>
          <!--<router-link to="/policyRetrieve" class="more-info">查看更多</router-link>-->
          <p class="more-info" @click="toMore">查看更多</p>
        </div>
      </el-tab-pane>
<!--      <el-tab-pane label="财税头条" name="second">
        <div class="list">
          <div class="row-item" v-for="n in 10" :key="n">
            <div class="desc">国内首款全面实现智能化数据挖掘与分析的财税风险检测系统</div><div class="time">2022-02-18</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="行业重点" name="third">
        <div class="list">
          <div class="row-item" v-for="n in 10" :key="n">
            <div class="desc">100多个维度、10000个指标360度无死角业财税全面风险检测</div><div class="time">2022-03-18</div>
          </div>
        </div>
      </el-tab-pane>-->
    </el-tabs>
    </div>
      </div>
    </div>

    <!--  产品特性  -->
    <div class="section section-2">
      <div class="title">产品特性</div>
      <div class="content">
        <div class="character-grid">
          <div class="character-grid-item no-hover" v-for="(item, i) in character" :key="i">
            <div class="character-grid-item-img">
              <img :src="item.icon" alt="" srcset="">
            </div>
            <div class="grid-item-name">{{item.key}}</div>
            <div class="grid-item-des">{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 关于我们 -->
    <div class="section section-3">
      <div class="title">关于我们</div>
      <div class="content">
        <div class="left">
          <img src="../assets/images/aboutUs.jpg" alt="" width="100%">
        </div>
        <div class="right">
          <div class="desc">
            <p>“税易答”是由深圳数隆科技公司自主研发的财税数字化管理系统，是为广大中小企业提供智能、精准、安全的财务数据分析、税务风险测评、纳税流程优化及财税数字解决方案的综合服务平台。</p>
            <p>深圳数隆科技公司是一家专业提供财务、税务、咨询领域数字技术研发与数字技术应用、专门提供财务管理、税务合规、财税数字化转型的综合服务供应商。</p>
            <p>我们的目标是致力于打造新时代、新技术、新模式下的财税数智云服务平台，协助客户发现、挖掘并实现企业财税新价值！</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {
      activeName: 'first',
      character: [
        { icon: require('../assets/images/product-icon1.png'), desc: '全面多维度财税检测分析', key: '全面' },
        { icon: require('../assets/images/product-icon2.png'), desc: '财税风险疑点精准锁定', key: '精准' },
        { icon: require('../assets/images/product-icon3.png'), desc: '匿名检测，保障数据安全', key: '安全' },
        { icon: require('../assets/images/product-icon4.png'), desc: '智能化的数据挖掘与分析', key: '智能' },
        { icon: require('../assets/images/product-icon5.png'), desc: '免费安心使用，快速出报表', key: '免费' }
      ],
      query: {
        pageSize: 10,
        pageNum: 1
      },
      data1: [],
      swiperData: [],
      loading: false
    }
  },
  methods: {
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    getTaxCategory () {
      this.loading = true
      this.API.getPolicySearchPage(this.query).then(({ data }) => {
        this.data1 = data.list
      }).finally(() => {
        this.loading = false
      })
    },
    toDetail (item) {
      // this.$router.push(`/policyDetail/${item.id}`)
      window.open(`/#/policyDetail/${item.id}`)
    },
    getSwiperData () {
      this.API.getSwiperPicture().then(({ data }) => {
        this.swiperData = data
      })
    },
    toMore () {
      window.open('/#/policyRetrieve')
    }
  },
  created () {
    this.getTaxCategory()
    this.getSwiperData()
  }
}
</script>

<style scoped lang="scss">
.page-index{
  background-color: #fff;
  .section{
    width: 1200px;
    margin: 0 auto;
    .title{
      color: #333;
      font-size: 30px;
      padding: 60px 0 30px;
    }
    &.section-1{
      .content {
        display: flex;
        .left{
          width: 520px;
          .el-carousel__item:nth-child(2n) {
            background: #99a9bf;
          }
          .el-carousel__item:nth-child(2n+1) {
            background: #d3dce6;
          }
        }
        .right{
          flex: 1;
          margin-left: 50px;
          .list{
            min-height: 420px;
            .row-item{
              display: flex;
              align-items: center;
              padding: 10px 0;
              border-bottom: solid 1px #e8e8e8;
              cursor: pointer;
              .desc{
                flex:1;
                text-align: left;
                font-size: 15px;
                color: #333;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 0;
                &:hover{
                  color: #20a0ff;
                }
                &::before{
                  content: "";
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  background-color: orangered;
                  margin-right: 10px;
                  display: inline-block;
                }
              }
              .time{
                width: 100px;
                color: #acacac;
                font-size: 14px;
              }
            }
            .more-info{
              color: #409EFF;
              line-height: 40px;
              cursor: pointer;
            }
          }
        }
      }
    }
    &.section-2{
      .character-grid{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .character-grid-item{
          flex: 1;
          width: 270px;
          .grid-item-name{
            font-size: 20px;
            color: #ff8800;
            margin-top: 15px;
          }
          .grid-item-des{
            font-size: 14px;
            line-height: 26px;
            height: 52px;
            color: rgba(94, 96, 104, 0.7);
          }
        }
      }
    }
    &.section-3{
      .content {
        display: flex;
        .left {
          width: 520px;
        }
        .right {
          flex: 1;
          margin-left: 50px;
          .desc{
            color: #6f6f6f;
            line-height: 44px;
            text-align: left;
            text-indent: 32px;
            font-size: 19px;
          }
        }
      }
    }
  }
}
</style>
